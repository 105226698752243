<template>
  <div>
    <c-message
      v-if="hasErrors"
      class="mb-8"
      type="error"
    >
    <p>{{ errorMsg }}</p>
    </c-message>
    <form
      ref="form"
      @submit.prevent="submit"
    >
      <c-title>Zusammenfassung und Erklärungen</c-title>
      <c-form-fieldset
        v-if="reportData"
        class="mt-8 mb-8"
        legend="Zusammenfassung Antragsformular"
      >
        <c-summary-list>
          <c-summary-list-item title="Anrede">
            {{ reportData.salutation }}
          </c-summary-list-item>
          <c-summary-list-item title="Vorname">
            {{ reportData.name }}
          </c-summary-list-item>
          <c-summary-list-item title="Nachname">
            {{ reportData.surname }}
          </c-summary-list-item>
          <c-summary-list-item title="Ansprechpartner*in">
            {{ reportData.contact_person }}
          </c-summary-list-item>
          <c-summary-list-item title="Institution">
            {{ reportData.company }}
          </c-summary-list-item>
          <c-summary-list-item title="Evtl. Adresszusatz">
            {{ reportData.address_additional_info }}
          </c-summary-list-item>
          <c-summary-list-item title="Anschrift">
            {{ reportData.address }}
            <br>
            {{ reportData.postal_code }} {{ reportData.city }}
          </c-summary-list-item>
          <c-summary-list-item title="Telefonnummer">
            {{ reportData.contact_number }}
          </c-summary-list-item>
          <c-summary-list-item title="E-Mail-Adresse">
            {{ reportData.email }}
          </c-summary-list-item>
          <c-summary-list-item title="Titel des Projekts">
            {{ reportData.project_title }}
          </c-summary-list-item>
          <c-summary-list-item title="Sparte">
            {{ reportData.branch }}
          </c-summary-list-item>
          <c-summary-list-item title="Erster Veranstaltungstag">
            {{ reportData.event_first_day }}
          </c-summary-list-item>
          <c-summary-list-item title="Laufzeit/weitere Termine">
            {{ reportData.duration }}
          </c-summary-list-item>
          <c-summary-list-item title="Veranstaltungsort">
            {{ reportData.event_location }}
          </c-summary-list-item>
          <c-summary-list-item title="Kooperationspartner*in">
            {{ reportData.cooperation_partner }}
          </c-summary-list-item>
          <c-summary-list-item title="Kurzbeschreibung des Vorhabens und Nennung der Projektbeteiligten">
            {{ reportData.project_desc }}
          </c-summary-list-item>
          <c-summary-list-item title="Konzept">
            <div v-if="reportData.concept_file.name">
              Datei: {{ reportData.concept_file.name }}
            </div>
          </c-summary-list-item>
          <c-summary-list-item title="Gesamtkosten (in Euro)">
            {{ reportData.total_cost }}
          </c-summary-list-item>
          <c-summary-list-item title="Geplante Einnahmen (in Euro, Eintrittserlöse o. ä.)">
            {{ reportData.planned_income }}
          </c-summary-list-item>
          <c-summary-list-item title="Eigenmittel/Eigenleistung (in Euro)">
            {{ reportData.funds }}
          </c-summary-list-item>
          <c-summary-list-item title="Gesicherte Drittmittel (in Euro)">
            {{ reportData.secured_third_party_funds }}
          </c-summary-list-item>
          <c-summary-list-item title="zugesagt durch">
            {{ reportData.secured_third_party_funds_desc }}
          </c-summary-list-item>
          <c-summary-list-item title="Ungesicherte Drittmittel (in Euro)">
            {{ reportData.unsecured_third_party_funds }}
          </c-summary-list-item>
          <c-summary-list-item title="beantragt/noch zu beantragen bei">
            {{ reportData.unsecured_third_party_funds_desc }}
          </c-summary-list-item>
          <c-summary-list-item title="Bei der Hamburgischen Kulturstiftung hiermit beantragt (in Euro)">
            {{ reportData.requested_amount }}
          </c-summary-list-item>
          <c-summary-list-item title="Förderung erhalten">
            {{ reportData.received_fund }}
          </c-summary-list-item>
          <c-summary-list-item
            v-if="reportData.received_fund === 'Ja'"
            title="Informationen Förderprojekt"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="formatTextarea(reportData.received_fund_desc)" />
          </c-summary-list-item>
        </c-summary-list>
      </c-form-fieldset>

      <c-form-fieldset
        legend="Erklärungen"
        class="mb-8"
      >
        <c-form-checkbox
          v-model="fields.consent_privacy"
          class="mb-5"
          label="Ich erkläre mich mit der Speicherung und Verarbeitung der angegebenen Daten im Rahmen des Entscheidungsverfahrens einverstanden. Die Unterlagen werden nur von den Mitarbeiter*innen und Gremienmitgliedern der Hamburgischen Kulturstiftung gelesen und nicht an Dritte weitergeben."
          required
        />

        <c-form-checkbox
          v-model="fields.consent_mention"
          class="mb-5"
          label="Ich erkläre mich einverstanden, dass im Falle einer Förderung ggf. die Förderdaten (Name, Titel und Thema des geförderten Vorhabens, Höhe der Förderung) im Rahmen von Pressemeldungen, Stiftungspublikationen und im Internet veröffentlicht werden."
          required
        />

        <c-form-checkbox
          v-model="fields.consent_publication"
          class="mb-5"
          label="Ich versichere die Richtigkeit und Vollständigkeit der vorstehenden Angaben."
          required
        />
      </c-form-fieldset>

      <c-form-text
        v-model="fields.signature_and_date"
        :errors="errors.signature_and_date"
        class="mb-8"
        label="Vollständiger Name und Datum"
        help="Dieses Formular wurde digital erstellt und gilt als unterschrieben von:"
        maxlength="400"
        required
      />

      <c-message
        v-if="unhandledError.status"
        type="error"
        class="mb-8"
      >
        <p v-if="unhandledError.message">
          {{ unhandledError.message }}
        </p>

        <template v-else>
          <p class="mb-3">
            Bei der Übertragung des Antrags kam es zu einem Fehler. Bitte probieren Sie es zu einem späteren Zeitpunkt
            erneut.
          </p>
          <p class="mb-3">
            Prüfen Sie bitte auch, ob die hochgeladenen Dateien zu groß sind.
          </p>
          <p class="mb-3">
            Sollte das Problem weiterhin bestehen, wenden Sie sich mit der folgenden Fehlermeldung an uns:
          </p>
          <p>
            {{ unhandledError.status }} {{ unhandledError.fallback }}
          </p>
        </template>
      </c-message>

      <c-button
        type="button"
        class="mr-5"
        color="secondary"
        @click="previous"
      >
        Zurück
      </c-button>

      <c-button :disabled="(isLoading || !published)">
        Antragsformular abschicken
      </c-button>

      <transition name="fade">
        <c-loading v-if="isLoading" />
      </transition>
    </form>
  </div>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

import CLoading from '../components/CLoading.vue'
import CMessage from '../components/CMessage.vue'
import CSummaryList from '../components/CSummaryList.vue'
import CSummaryListItem from '../components/CSummaryListItem.vue'

import { postRequest } from '../api.js'
import CFormCheckbox from '../components/CFormCheckbox.vue'

export default {
  name: 'VRequestStep3',

  components: {
    CLoading,
    CMessage,
    CSummaryList,
    CSummaryListItem,
    CFormCheckbox
  },

  extends: BRequestStep,

  data () {
    return {
      step: 3,
      id: 'summaryTerms',
      isLoading: false,
      unhandledError: {
        status: null,
        message: '',
        fallback: ''
      },
      fields: {
        consent_privacy: false,
        consent_publication: false,
        consent_mention: false,
        signature_and_date: ''
      }
    }
  },

  computed: {
    hasErrors () {
      return this.$store.state.errors.steps.includes(this.step)
    },
    steps () {
      return this.$store.state.steps
    },
    reportData () {
      const Object = this.steps[2]?.fields
      Object.name = this.steps[0]?.fields.name
      Object.salutation = this.steps[0]?.fields.salutation
      Object.surname = this.steps[0]?.fields.surname
      Object.contact_person = this.steps[0]?.fields.contact_person
      Object.company = this.steps[0]?.fields.company
      Object.address_additional_info = this.steps[0]?.fields.address_additional_info
      Object.address = this.steps[0]?.fields.address
      Object.contact_number = this.steps[0]?.fields.contact_number
      Object.email = this.steps[0]?.fields.email
      Object.project_title = this.steps[1]?.fields.project_title
      Object.branch = this.steps[1]?.fields.branch
      Object.event_first_day = this.steps[1]?.fields.event_first_day
      Object.duration = this.steps[1]?.fields.duration
      Object.project_desc = this.steps[1]?.fields.project_desc
      Object.event_location = this.steps[1]?.fields.event_location
      Object.concept_file = this.steps[1]?.fields.concept_file
      Object.cooperation_partner = this.steps[1]?.fields.cooperation_partner
      return Object
    },
    salutation () {
      return {
        f: 'Frau',
        m: 'Herr',
        n: false
      }[this.reportData.salutation]
    },
    published () {
      return this.$store.state.publishedInfo.published
    }
  },

  methods: {
    formatTextarea (text) {
      // First split on double line breaks to create <p>.
      // Second split on single line breaks to create <br>.
      return text
        .split('\n\n')
        .map(p => `<p class="mb-3">${p}</p>`)
        .join('')
        .split('\n')
        .join('<br>')
    },
    getStepWithFirstError (fields, mapping) {
      if (!fields.length) return 'request-step-2'
      for (const [key, values] of Object.entries(mapping)) {
        if (fields.some((str) => values.includes(str))) {
          return key
        }
      }
    },
    async submit () {
      this.isLoading = true
      this.unhandledError.status = null
      this.unhandledError.message = ''
      this.unhandledError.fallback = ''

      this.save({ completed: true })

      const form = new FormData()

      const stepFieldMapping = {
        'request-step-0': [],
        'request-step-1': [],
        'request-step-2': [],
        'request-step-3': []
      }

      for (const [index, step] of this.steps.entries()) {
        for (const field in step.fields) {
          form.append(field, step.fields[field])
          stepFieldMapping['request-step-' + index].push(field)
        }
      }

      if (
        this.fields.consent_privacy &&
        this.fields.consent_publication &&
        this.fields.consent_mention
      ) {
        form.append('has_accepted_conditions', true)
      } else {
        this.unhandledError.status = 10
        this.unhandledError.fallback = 'Bitte akzeptieren Sie alle Erklärungen.'
        this.isLoading = false
        return
      }

      try {
        await postRequest(form)
        this.$router.push({ name: 'thank-you' })
        this.$store.commit('reset')
      } catch (error) {
        const response = error.response

        // redirects to home if the application form was paused while editing
        if (response.status === 403) {
          this.$router.push({ name: 'home' })
          this.$router.go()
          return
        }

        // A JSON response is Django's form validation.
        if (response.headers['content-type'] === 'application/json') {
          const errorFields = Object.keys(response.data)
          this.$store.commit(
            'errors', {
              fields: response.data,
              steps: errorFields.map(n => Number(this.getStepWithFirstError([n], stepFieldMapping).slice(-1)))
            }
          )
          this.$router.push({ name: this.getStepWithFirstError(errorFields, stepFieldMapping) })
          return
        }

        // Everything else is nginx's stuff.
        const message = {
          413: 'Ihre hochgeladenen Dateien sind zu groß. Bitte wählen Sie kleinere Dateien aus und probieren es erneut.'
        }[response?.status]

        if (message) {
          this.unhandledError.message = message
        } else {
          this.unhandledError.fallback = response?.statusText ?? error.message
        }

        this.unhandledError.status = response?.status ?? error.name
        this.isLoading = false
      }
    }
  }
}
</script>
